import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'
import { responsiveScale } from '../../styles/helpers'
import LeadHeading from '../LeadHeading/LeadHeading'
import Block from '../primitives/Block'
import Stack from '../primitives/Stack'
import IllustrationBlock from './IllustrationBlock'
import { BlockPaddingProps } from './types'

type Props = BlockPaddingProps & {
  heading: ReactNode
  content?: ReactNode
  image?: IGatsbyImageData
  alt?: string
  link?: { title: string; href: string }
  icon?: string
  title?: ReactNode
}

const LeadBlock = ({
  icon,
  title,
  heading,
  content,
  image,
  alt,
  link,
  paddingTop,
  paddingBottom,
}: Props) => {
  return (
    <Stack
      paddingTop={paddingTop || responsiveScale(icon ? 64 : 92)}
      paddingBottom={paddingBottom}
      space={responsiveScale(48)}
    >
      <Block>
        <LeadHeading
          icon={icon}
          title={title}
          heading={heading}
          content={content}
          link={link}
        />
      </Block>
      {image ? <IllustrationBlock image={image} alt={alt || ''} /> : null}
    </Stack>
  )
}

export default LeadBlock
