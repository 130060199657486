import { IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import Block from '../primitives/Block'

import YoutubeVideo from '../YoutubeVideo'
import { BlockPaddingProps } from './types'

type Props = BlockPaddingProps & {
  thumbnail: IGatsbyImageData
  videoId: string
}

const VideoBlock = ({
  thumbnail,
  videoId,
  paddingTop,
  paddingBottom,
}: Props) => {
  return (
    <Block paddingTop={paddingTop} paddingBottom={paddingBottom}>
      <YoutubeVideo thumbnail={thumbnail} videoId={videoId} />
    </Block>
  )
}

export default VideoBlock
