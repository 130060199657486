import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import Layout from '../../components/Layout'
import MetaImage from '../../assets/images/meta/linkedin-event-rethinking-developer-productivity.png'
import LeadBlock from '../../components/blocks/LeadBlock'
import VideoBlock from '../../components/blocks/VideoBlock'
import GlobalStyleReset from '../../components/system/GlobalStyleReset'
import { responsiveScale } from '../../styles/helpers'
import CTABlock from '../../components/blocks/CTABlock'
import Box from '../../components/primitives/Box'

export const query = graphql`
  {
    videoThumbnail: file(
      relativePath: { eq: "images/rethinking-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
  }
`

const VideoPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="Rethinking developer productivity [LinkedIn Live]"
      variant="light"
      isNew
      description="Swarmia CEO Otto Hilska and Field CTO Rebecca Murphey share insights from engineering leaders on prioritizing work, communicating with the board, and fostering continuous improvement — all without alienating developers"
      metaImage={MetaImage}
    >
      <GlobalStyleReset>
        <LeadBlock
          heading={<>Rethinking developer productivity [LinkedIn Live]</>}
          content="Swarmia CEO Otto Hilska and Field CTO Rebecca Murphey share insights from engineering leaders on prioritizing work, communicating with the board, and fostering continuous improvement — all without alienating developers"
        />
        <VideoBlock
          videoId="eDMKasOEWMk"
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(32)}
          thumbnail={getImage(data.videoThumbnail)!}
        />
      </GlobalStyleReset>
      <Box paddingBottom={responsiveScale(92)} />
      <CTABlock
        title={
          <>Start systematically improving developer productivity today.</>
        }
      />
    </Layout>
  )
}

export default VideoPage
