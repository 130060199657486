import React, { ReactNode } from 'react'
import { trackClick } from '../../analyticsUtils'
import CTABackground from '../../assets/images/product/cta-background.svg'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import Stack from '../primitives/Stack'
import Button from '../system/Button'

type CTAButton = {
  text: string
  href: string
}

type Props = {
  title?: ReactNode
  primaryButton?: CTAButton
  secondaryButton?: CTAButton | null
  customCta?: ReactNode
}

const desktopButtonMaxWidth = 320

const CTABlock = ({
  title = 'Join the effective software organizations using Swarmia today.',
  primaryButton = { text: 'Get a demo', href: '/demo/' },
  secondaryButton = {
    text: 'Start free trial',
    href: 'https://app.swarmia.com/signup/',
  },
  customCta = null,
}: Props) => {
  return (
    <Box
      backgroundColor="darkBlue"
      backgroundImage={`url(${CTABackground})`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="white"
      minHeight={{ xs: undefined, sm: 580 }}
      paddingY={{ xs: 48, xsm: 64, md: 92 }}
      position="relative"
    >
      <Box
        width="100%"
        height="100%"
        backgroundColor="black"
        opacity={0.5}
        position="absolute"
        left={0}
        top={0}
        zIndex={0}
      />
      <Box position="relative" zIndex={1}>
        <Block>
          <Stack alignItems="center">
            <Box
              fontFamily="FactorA"
              fontSize={{ xs: 28, xsm: 32, sm: 40, lg: 48 }}
              fontWeight={500}
              lineHeight="120%"
              letterSpacing="-0.01em"
              maxWidth={{ xs: 500, sm: 'mediumBlock' }}
              textAlign="center"
              color="white"
            >
              {title}
            </Box>
            {customCta ? (
              customCta
            ) : (
              <Row
                paddingTop={{ xs: 32, sm: 48 }}
                space={16}
                flexDirection={{ xs: 'column', md: 'row' }}
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={trackClick('CTABlock - Primary Button')}
                  href={primaryButton.href}
                  variant="primary"
                  size="large"
                  width="100%"
                  maxWidth={{
                    xs: '100%',
                    xsm: desktopButtonMaxWidth,
                  }}
                >
                  {primaryButton.text}
                </Button>
                {secondaryButton ? (
                  <Button
                    onClick={trackClick('CTABlock - Secondary Button')}
                    href={secondaryButton.href}
                    size="large"
                    variant="secondaryWithBorder"
                    width="100%"
                    maxWidth={{
                      xs: '100%',
                      xsm: desktopButtonMaxWidth,
                    }}
                  >
                    {secondaryButton.text}
                  </Button>
                ) : null}
              </Row>
            )}
          </Stack>
        </Block>
      </Box>
    </Box>
  )
}

export default CTABlock
