import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'
import Block from '../primitives/Block'
import Box from '../primitives/Box'
import { BlockPaddingProps } from './types'

type Props = BlockPaddingProps & {
  image: IGatsbyImageData
  alt?: string
  noPlaceHolder?: boolean
  rounded?: boolean
  shadow?: boolean
}

const IllustrationBlock = ({
  image,
  alt,
  noPlaceHolder = false,
  rounded = false,
  shadow = false,
  paddingTop,
  paddingBottom,
}: Props) => {
  return (
    <Block
      display="flex"
      justifyContent="center"
      maxWidth="100%"
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <Box
        isolation="isolate"
        overflow="hidden"
        display="inline-flex"
        boxShadow={shadow ? 'strong' : undefined}
        borderRadius={rounded ? { xs: 8, sm: 16, md: 24 } : undefined}
        css={
          noPlaceHolder
            ? `
          [data-placeholder-image] {
            display: none;
          }
        `
            : undefined
        }
      >
        <GatsbyImage image={image} alt={alt || ''} />
      </Box>
    </Block>
  )
}

export default IllustrationBlock
